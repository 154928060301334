@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Header */

.panel {
  width: 95%;
}

.navbar {
  width: 95%;
}

nav {
  width: 95%;
  margin: 0 auto;
  background-color: white;
}

nav li:first-child {
  margin-right: auto;
}

nav ul {
  margin: 0;
  padding: 0 0px;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 18px;
  align-items: baseline;
  padding: 5px;
}

nav a {
  color: black;
  text-decoration: none;
  margin: 0;
}

.title-logo {
  font-weight: 700;
  font-size: larger;
}

.header-button-right {
  font-weight: 600;
}

.header-button-right:hover {
  text-decoration: underline;
}

.banner {
  /* display: flex;
  justify-content: center;
  position: relative;
  background-size: cover; */
  height: 480px;
}

.banner-image {
  background: url("https://i.postimg.cc/J7Q4g05n/Group-19.png") 75% 25%;

  /* 

  justify-content: center;
  background-size: cover;
  position: absolute;
  display: flex;
  border-radius: 25px; */
}

.banner-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 33vh;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
}

.banner-info > h2 {
  color: white;
  text-align: center;
  font-size: clamp(2.2rem, 3vw, 6rem);
  padding-left: 60px;
  padding-right: 60px;
  font-weight: 700;
  position: absolute;
  top: 60%;
}

/* Body */

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
}

/* Card */

.card {
  height: 415px;
}

.card:hover {
  transform: scale(1.02);
}
